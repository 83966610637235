
export default function getRandomInt() {
    return Math.floor(Math.random())+2*Date.now();
}


export const numberFormat = function (number) {
    return (number || "")
      .toString()
      .replace(/^0|\./g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  export const priceFormat = function (number) {
    return number ? `${numberFormat(number)}` : `0`;
  }