import React, {FC, LegacyRef} from 'react'

interface Props {
  pdfMode?: boolean
  ref?: LegacyRef<any>
}

const Document: FC<Props> = ({ children }) => {
  return <>{children}</>
}

export default Document
